import { okHttpStatus, uuidv4, downloadFileAttachmentFromHttpResponse } from './helpers'
import { registerLoadingTask, deregisterLoadingTask } from './loading-service'
import { useContext, useRef, useState, createRef, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { ReactComponent as CheckIcon } from '../assets/check.svg'
import { ReactComponent as ExclamationCirclIcon } from '../assets/exclamation-circle.svg'
import React from 'react'
import { TOAST_ANIMATION_DURATION } from '../constants'
import { throttle } from 'lodash'

import moment from 'moment'
import { auth } from './firebase'
import { navigate } from '@reach/router'
import { IAppActions } from '../stores/app-actions'

/* 
	Shared interface for HTTP requests that have an HTTP status code.
	Allows us to use shared implementation for both Axios and Fetch HTTP requests since both have a number property named 'status'.
*/
interface IHasHTTPStatusCode {
	status: number
	statusText: string
}

/* 
	Provides a wrapper for HTTP requests that does the followin:
	- Waits for a request 
	- Shows/hides loading spinner while request is in progress
	- Adds a toast on success or error
	- Returns the response from the HTTP request
	- Calls async onSuccess and on onFailure callbacks after a request 
			(to allow for "grouped" operations to follow the initial request, but still happen before the loading spinner is stopped or a toast shown)
*/
interface IWrappedHTTPRequestOptions<ReturnType extends IHasHTTPStatusCode> {
	request: Promise<ReturnType>

	// Loading message is shown by default, but you can disable (e.g. you want a toasts, but don't want to hold up the UI with a loading indicator)
	disableLoading?: boolean

	// Toasts are shown by default for both success and failure, you can disable one or both as desired
	disableFailureToast?: boolean
	disableSuccessToast?: boolean

	// Optional messages for the toast - generic fallbacks will be used if no messages are provided
	toastSuccessMessage?: string
	toastErrorMessage?: string

	/* 
		Optional function that will be called after a SUCCESSFUL request (can be used for refreshing data after an operation is performed)
		This is provided here so that a refresh can be included as part of the UX for a single action (instead of having to separately refresh the data after an action was complete)
	*/
	onRequestSuccess?: () => Promise<void>

	/* 
		Optional function that will be called after a FAILED request (can be used for hiding modals, or showing other error state)
	*/
	onRequestFailure?: () => Promise<void>
}


export const useHTTPRequestUiWrapper = () => {
	const appActions = useContext(AppActionContext)!

	const wrapApiRequest = async <ReturnType extends IHasHTTPStatusCode,>(options: IWrappedHTTPRequestOptions<ReturnType>): Promise<ReturnType> => {
		const { request, toastSuccessMessage, toastErrorMessage, onRequestSuccess, onRequestFailure, disableSuccessToast, disableFailureToast, disableLoading } = options

		const taskId = disableLoading ? '' : registerLoadingTask()

		try {
			const query = await request

			if (okHttpStatus(query.status)) {
				if (onRequestSuccess) await onRequestSuccess()
				deregisterLoadingTask(taskId)

				if (!disableSuccessToast && appActions) {
					appActions.addToast({
						id: uuidv4(),
						timestamp: new Date(),
						title: 'Success',
						body: toastSuccessMessage || 'Operation was successful.',
						icon: <CheckIcon className='text-success' />,
						autoDismissTimeOut: TOAST_ANIMATION_DURATION + 2500,
					})
				}
			} else {
				if (onRequestFailure) onRequestFailure()
				deregisterLoadingTask(taskId)
				if (!disableFailureToast && appActions) {
					appActions.addToast({
						id: uuidv4(),
						timestamp: new Date(),
						title: 'Error',
						body: toastErrorMessage || query.statusText || 'There was an error performing your request.',
						icon: <ExclamationCirclIcon className='text-danger' />,
					})
				}
			}
			return query
		} catch (e) {
			console.log('this was the failure', e)
			if (onRequestFailure) onRequestFailure()
			deregisterLoadingTask(taskId)
			if (!disableFailureToast && appActions) {
				appActions.addToast({
					id: uuidv4(),
					timestamp: new Date(),
					title: 'Error',
					body: (
						<div>
							{toastErrorMessage ? <p>{toastErrorMessage}</p> : null}
							Unexpected error: {`${e.message}`}
						</div>
					),
					icon: <ExclamationCirclIcon className='text-danger' />
				})
			}
			throw (e)
		}
	}

	return wrapApiRequest
}


export const useAsyncFnThrottleWrapper = <FnReturnType, FnArgumentType extends any[]>(fn: (...args: FnArgumentType) => FnReturnType | Promise<FnReturnType>, ms: number = 500) => {
	const timeout = useRef<ReturnType<typeof setTimeout>>()

	const throttledFn = async (...args: FnArgumentType): Promise<FnReturnType> => {
		if (timeout.current) clearTimeout(timeout.current)

		return new Promise<FnReturnType>((resolve, reject) => {
			timeout.current = setTimeout(() => {
				try {
					resolve(fn(...args))
				} catch (e) {
					reject(e)
				}
			}, ms)
		})
	}

	return throttledFn
}

export const useVisibility = <Element extends HTMLElement>(
	offset = 0,
	throttleMilliseconds = 100
): [Boolean, React.RefObject<Element>] => {
	const [isVisible, setIsVisible] = useState(false)
	const currentElement = createRef<Element>()

	const onScroll = throttle(() => {
		if (!currentElement.current) {
			setIsVisible(false)
			return
		}
		const top = currentElement.current.getBoundingClientRect().top
		setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight)
	}, throttleMilliseconds)

	useEffect(() => {
		window.addEventListener("scroll", onScroll)
		return () => window.removeEventListener("scroll", onScroll)
	})

	return [isVisible, currentElement]
}

export const useModal = (): [
	{ modalId: string, show: boolean, closeModal: () => void },
	((show?: boolean) => void)
] => {
	const [modalState, setModalState] = useState({
		modalId: uuidv4(),
		show: false,
	})

	const showHideModal = (show?: boolean) => {
		if (show === undefined) show = !modalState.show
		setModalState({ ...modalState, show })
	}

	return [
		{
			...modalState,
			closeModal: () => showHideModal(false)
		},
		showHideModal
	]
}


export const useAuthGuard = (appActions: IAppActions) => {
	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			appActions.authReady()
			if (user) {
				appActions.setUser(user)
			} else {
				appActions.setUser(null)
				navigate('/login')
			}
		})
	}, [])
}