import React, { useState, useEffect, useContext } from 'react'
import { useField, FieldAttributes } from 'formik'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { isNullOrUndefined } from 'util'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { portalRoot, uuidv4 } from '../services/helpers'
import moment from 'moment'
import { Editor } from '@tinymce/tinymce-react'
import Select, { Styles } from 'react-select'
import { flatten } from 'lodash'
import { TooltipInfo } from './partials'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading, LoadingPropsSizeEnum } from './loading'

interface IFieldProps {
	fieldProps: {
		label: string | null | JSX.Element
		name: string
		[additionalInputAttributes: string]: any
		placeholder?: string
		labeltooltip?: string
	}
	labelStyle?: React.CSSProperties
	icon?: JSX.Element
	disabled?: boolean
	hideInput?: boolean
}
export const TextField = (props: IFieldProps) => {
	return <Input {...props} />
}

interface ITextareaFieldProps extends IFieldProps {
	minHeight?: string
}
export const TextareaField = (props: ITextareaFieldProps) => {
	return <Input {...props} textarea={true} inputStyle={props.minHeight ? { minHeight: props.minHeight } : undefined} />
}

export const EmailField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'email',
	})
	return <Input fieldProps={inputFieldProps} />
}

export const PhoneField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'phone',
	})
	return <Input fieldProps={inputFieldProps} />
}

export const EinField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'ein',
	})
	return <Input fieldProps={inputFieldProps} />
}

export const DobField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'dob',
	})
	return <Input fieldProps={inputFieldProps} />
}


export const ZipField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'zip',
	})
	return <Input fieldProps={inputFieldProps} />
}

export const PasswordField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'password',
	})
	return <Input fieldProps={inputFieldProps} />
}

export const DateStringField = (props: IFieldProps) => {
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'date-string',
	})
	return <Input fieldProps={inputFieldProps} />
}

export interface ISelectFieldOption {
	value: string
	label: string
}
export const isSelectFieldOption = (object: any | null | undefined): object is ISelectFieldOption => !!object && 'value' in object
export interface ISelectFieldOptionGroup {
	id: string
	label: string
	options: ISelectFieldOption[]
}
interface ISelectFieldProps extends IFieldProps {
	fieldProps: {
		label: string | null
		name: string
		[additionalInputAttributes: string]: any
		placeholder?: string
	}
	options?: ISelectFieldOption[]
	optionGroups?: ISelectFieldOptionGroup[]
	multiple?: boolean
}
export const SelectField = (props: ISelectFieldProps) => {
	const { fieldProps, options, optionGroups, multiple, disabled } = props

	const [field, meta, helpers] = useField(fieldProps)
	const { error } = meta

	// Map field state to CSS class strings
	const fieldStateClasses = `${error ? 'is-invalid' : ''}`.trim()

	// const renderOptions = (_options: ISelectFieldOption[] | undefined) => {
	// 	return _options?.map(option => <option className='text-body' key={option.value} value={option.value}>{option.label}</option>)
	// }

	let value: ISelectFieldOption[] = []
	if (field.value instanceof Array) {
		if (optionGroups) {
			value = flatten(optionGroups.map(o => o.options)).filter(o => field.value.includes(o.value))
		} else if (options) {
			value = options.filter(o => field.value.includes(o.value))
		}
	} else if (typeof field.value === 'string') {
		if (optionGroups) {
			value = flatten(optionGroups.map(o => o.options)).filter(o => field.value === o.value)
		} else if (options) {
			value = options.filter(o => field.value === o.value)
		}
	} else if (typeof field.value === 'number') {
		if (optionGroups) {
			value = flatten(optionGroups.map(o => o.options)).filter(o => field.value == o.value)
		} else if (options) {
			value = options.filter(o => field.value == o.value)
		}
	}

	const customStyles: Partial<Styles> = {
		multiValueLabel: (styles) => ({
			...styles,
			textOverflow: '',
			overflow: '',
			whiteSpace: 'inherit'
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 })
	}

	return (
		<div className={`form-group ${fieldStateClasses}`}>
			<label htmlFor={fieldProps.name} dangerouslySetInnerHTML={{ __html: fieldProps.label || '' }}></label>
			{/* <select disabled={disabled} className={`form-control custom-select ${fieldStateClasses} ${field.value === '' ? 'text-muted' : ''}`} {...field} {...fieldProps} id={fieldProps.name} multiple={multiple} value={field.value}>
				{fieldProps.placeholder ? <option value='' className='text-muted'>{fieldProps.placeholder}</option> : null}
				{optionGroups?.map(optionGroup =>
					<optgroup key={optionGroup.optionGroupId} label={optionGroup.optionGroupLabel}>
						{renderOptions(optionGroup.options)}
					</optgroup>
				)}
				{renderOptions(options)}
			</select> */}

			<Select
				options={optionGroups ? optionGroups : options}
				isDisabled={disabled}
				placeholder={fieldProps.placeholder}
				isMulti={multiple}
				{...field}
				{...fieldProps}
				value={value}
				id={fieldProps.name}
				onChange={(selection) => {
					if (selection instanceof Array) {
						helpers.setValue(selection.map(o => o.value))
					} else if (isSelectFieldOption(selection)) {
						helpers.setValue(selection.value)
					} else {
						helpers.setValue(null)
					}
				}}
				styles={customStyles}
				menuPortalTarget={portalRoot}

			/>

			{/* Only show errors if the field has been touched */}
			{meta.error ?
				<div className='invalid-feedback' style={{ display: 'block' }}>{meta.error}</div>
				:
				<div style={{ height: '19.4px' }} />
			}
		</div>
	)
}

interface ISimpleSelectFieldProps extends ISelectFieldProps {
	onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => void
}
export const SimpleSelectField = (props: ISimpleSelectFieldProps) => {
	const { options, optionGroups, multiple, disabled, fieldProps, onChange } = props

	return (
		<div className={`form-group`}>
			<label htmlFor={fieldProps.name} dangerouslySetInnerHTML={{ __html: fieldProps.label || '' }}></label>

			<Select
				options={optionGroups ? optionGroups : options}
				isDisabled={disabled}
				placeholder={fieldProps.placeholder}
				isMulti={multiple}
				{...fieldProps}
				id={fieldProps.name}
				onChange={(selection) => {
					if (selection instanceof Array) {
						onChange(selection)
					} else if (isSelectFieldOption(selection)) {
						onChange(selection)
					} else {
						onChange(null)
					}
				}}
			/>
		</div>
	)
}

interface INumberFieldProps extends IFieldProps {
	min?: number
	max?: number
	step?: number
	noFormat?: boolean
}
export const NumberField = (props: INumberFieldProps) => {
	const { min, max, step } = props
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'number',
		min,
		max,
		step,
	})
	return <Input {...props} fieldProps={inputFieldProps} />
}

interface ICurrencyFieldProps extends IFieldProps {
	min?: number
	max?: number
	step?: number
	noFormat?: boolean
}
export const CurrencyField = (props: ICurrencyFieldProps) => {
	const { min, max, step } = props
	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'currency',
		min,
		max,
		step,
	})
	return <Input {...props} fieldProps={inputFieldProps} />
}

interface IYesNoFieldProps extends IFieldProps {
	setFieldValue?: (field: any, value: any) => void,
}
export const YesNoField = (props: IYesNoFieldProps) => {
	return <ToggleField {...props} trueText='Yes' falseText='No' />
}

interface IToggleFieldProps extends IFieldProps {
	trueText: string | JSX.Element
	falseText: string | JSX.Element
}
export const ToggleField = (props: IToggleFieldProps) => {
	const { trueText, falseText } = props

	const inputFieldProps = Object.assign({}, props.fieldProps, {
		type: 'toggle',
		disabled: props.disabled
	})
	return <Input fieldProps={inputFieldProps} toggleField={{ trueText, falseText }} />
}


export const CheckboxField = (props: IFieldProps) => {
	// Unwrap our props
	const { fieldProps, disabled } = props

	// Unrwap goodies from Formik's useField() hook
	const [field, meta] = useField({ ...fieldProps })
	const { error, touched } = meta

	// Unique ID for the checkbox
	const [checkboxId] = useState(uuidv4())

	const fieldStateClasses = `${error && touched ? 'is-invalid' : ''}`.trim()


	return (
		<div className={`form-group ${fieldStateClasses}`}>
			<div className='form-check' style={{ display: 'flex' }}>
				<input
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-check-input ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the field props straing into the <input/>
					{...fieldProps}
					type='checkbox'
					placeholder={fieldProps.placeholder}
					id={checkboxId || fieldProps.name}
					checked={field.value}
					style={{ cursor: disabled ? 'default' : 'pointer' }}
					disabled={disabled}
				/>
				<label className='form-check-label unselectable' htmlFor={checkboxId || fieldProps.name} style={{ cursor: disabled ? 'default' : 'pointer', marginBottom: 3 }}>
					{fieldProps.labeltooltip ? <TooltipInfo style={{ marginBottom: 4 }} tooltipText={fieldProps.labeltooltip} /> : ''} {fieldProps.label}
				</label>
			</div>

			{/* Only show errors if the field has been touched */}
			{meta.touched && meta.error ?
				<div className='invalid-feedback' style={{ display: 'block' }}>{meta.error}</div>
				:
				null
			}

		</div>
	)
}

interface IFileFieldProps extends IFieldProps {
	accept?: string
	fileName?: string | null
}
export const FileField = (props: IFileFieldProps) => {
	// Unwrap our props
	const { fieldProps, accept, fileName, disabled } = props

	// Unrwap goodies from Formik's useField() hook
	const [field, meta, helpers] = useField(fieldProps)
	const { error, touched } = meta

	const [label, setLabel] = useState('Choose file')

	useEffect(() => {
		setLabel(fileName || 'Choose file')
	}, [fileName])

	const fieldStateClasses = `${error && touched ? 'is-invalid' : ''}`.trim()

	return (
		<div className={`form-group ${fieldStateClasses}`}>
			<div className='custom-file'>
				<input
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`custom-file-input ${fieldStateClasses}`}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type='file'
					placeholder={fieldProps.placeholder}
					id={fieldProps.id || fieldProps.name}
					name={field.name}
					onBlur={field.onBlur}
					onChange={e => {
						if (e.currentTarget.files?.length) {
							setLabel(e.currentTarget.files[0].name)
							helpers.setValue(e.currentTarget.files[0])
						} else {
							setLabel('Choose file')
						}
					}}
					onClick={e => {
						helpers.setValue('')
						setLabel('Choose file')
						e.currentTarget.value = ''
					}}
					accept={accept}
					disabled={disabled}
				/>
				<label className='custom-file-label' htmlFor={fieldProps.id || fieldProps.name}>
					{label}
				</label>
			</div>

			{meta.error ?
				<div className='invalid-feedback' style={{ display: 'block' }}>{meta.error}</div>
				:
				<div style={{ height: '19.4px' }} />
			}
		</div>
	)
}

interface ISimpleFileFieldProps {
	id: string
	accept: string
	disabled?: boolean
	fileName?: string
	onChange: (file: File | null) => void
}
export const SimpleFileField = (props: ISimpleFileFieldProps) => {
	// Unwrap our props
	const { accept, fileName, disabled, id, onChange } = props

	const [label, setLabel] = useState('Choose file')

	useEffect(() => {
		setLabel(fileName || 'Choose file')
	}, [fileName])

	return (
		<div className={`form-group`}>
			<div className='custom-file'>
				<input
					className={`custom-file-input`}
					type='file'
					id={id}
					onChange={e => {
						if (e.currentTarget.files?.length) {
							setLabel(e.currentTarget.files[0].name)
							onChange(e.currentTarget.files[0])
						} else {
							setLabel('Choose file')
						}
					}}
					onClick={e => {
						onChange(null)
						setLabel('Choose file')
						e.currentTarget.value = ''
					}}
					accept={accept}
					disabled={disabled}
				/>
				<label className='custom-file-label' htmlFor={id}>
					{label}
				</label>
			</div>
		</div>
	)
}

export interface ITinyMceField extends IFieldProps {
	height?: number
	plugins?: string[]
	toolbar?: string
}
export const TinyMceField = (props: ITinyMceField) => {
	const { fieldProps, height, plugins, toolbar, disabled } = props

	// Unrwap goodies from Formik's useField() hook
	const [field, meta, helpers] = useField(fieldProps)
	const { error, touched } = meta

	useEffect(() => {
		if (field.value && !touched) helpers.setTouched(true)
	}, [field.value])

	return (
		<div>
			<label htmlFor={fieldProps.id || fieldProps.name}>
				{fieldProps.label}
			</label>

			<Editor
				init={{
					height: height || 250,
					plugins: plugins || [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount'
					],
					toolbar: toolbar || 'fullscreen | undo redo | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | formatselect  | removeformat | help',
				}}
				onEditorChange={helpers.setValue}
				value={field.value}
				disabled={disabled}
			/>

			{/* Only show errors if the field has been touched */}
			{(touched || !isNullOrUndefined(field.value)) && error ?
				<div className='invalid-feedback' style={{ display: 'block' }}>{error}</div>
				:
				<div style={{ height: '19.4px' }} />
			}
		</div>
	)
}


const Input = (props: {
	fieldProps: string | FieldAttributes<any>,
	textarea?: boolean,
	readonly?: boolean
	inputStyle?: React.CSSProperties
	labelStyle?: React.CSSProperties
	icon?: JSX.Element
	noFormat?: boolean
	toggleField?: {
		trueText: string | JSX.Element
		falseText: string | JSX.Element
	}
	hideInput?: boolean
	disabled?: boolean
}) => {
	// Unwrap our props
	const { fieldProps, textarea, readonly, inputStyle, labelStyle, icon, noFormat, toggleField, hideInput, disabled } = props
	const { type, } = fieldProps

	// Unrwap goodies from Formik's useField() hook
	const [field, meta, helpers] = useField(fieldProps)
	const { error, touched } = meta

	// Map field state to CSS class strings
	const fieldStateClasses = `${error && touched ? 'is-invalid' : ''} ${icon ? 'has-icon' : ''}`.trim()

	let input = <div></div>

	switch (fieldProps.type) {
		case 'currency':
			const defaultMaskOptions = {
				prefix: '$',
				suffix: '',
				includeThousandsSeparator: true,
				thousandsSeparatorSymbol: ',',
				allowDecimal: true,
				decimalSymbol: '.',
				decimalLimit: 2, // how many digits allowed after the decimal
				allowNegative: false,
				allowLeadingZeroes: false,
			}

			const currencyMask = createNumberMask(defaultMaskOptions)

			input = (
				<MaskedInput
					mask={currencyMask}
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					// Show numeric keypad on iOS/Android
					inputMode='numeric'
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
					onChange={(ev) => helpers.setValue(ev.currentTarget.value.replace('$', '').replace(',', ''))}
				/>
			)
			break
		case 'phone':
			input = (
				<MaskedInput
					mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,]}
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type={type}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
				/>
			)
			break
		case 'date-string':
			input = (
				<MaskedInput
					mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type={type}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
				/>
			)
			break
		case 'ein':
			input = (
				<MaskedInput
					mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,]}

					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type={type}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
				/>
			)
			break
		case 'dob':
			input = (
				<MaskedInput
					mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}

					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type={type}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
				/>
			)
			break
		case 'zip':
			input = (
				<MaskedInput
					mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}

					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type={type}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						const cleaned = ('' + event.currentTarget.value).replace(/\D/g, '')
						helpers.setValue(cleaned)
					}}
				/>
			)
			break
		case 'number':
			input = (
				<input
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
					/* 
						We need to use a type of 'text' to have complete control over the field's value.
						If we use type of 'number' the onChange event does not fie with text input.
						This wouldn't be a problem, except only Chrome restricts type='number' <Input /> changes. 
						Firefox and Edge will allow text based inputs, but if the onChange doesn't fire we don't have a chance to sanitize the inputs.
						The end result is a text field that displays a different value (numbers and text) than the underlying form state (the last value of the input field when a number was pressed).
					*/
					type='text'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						const cleaned = ('' + event.currentTarget.value).replace(/\D/g, '')

						if (noFormat) {
							helpers.setValue(cleaned.length ? parseInt(cleaned) : '')
						} else {
							helpers.setValue(cleaned.length ? parseInt(cleaned).toLocaleString('en-US') : '')
						}

					}}
				/>
			)
			break
		case 'toggle':
			if (toggleField) {
				input = (
					<div className={`${fieldStateClasses}`}>
						<div className='form-check form-check-inline'>
							<input disabled={readonly} {...field} {...fieldProps} checked={field.value === true} className='form-check-input' type='radio' name={`${props.fieldProps.name}`} id={`${props.fieldProps.name}-yes`} value='1' onChange={_ => helpers.setValue(true)} />
							<label className='form-check-label' htmlFor={`${props.fieldProps.name}-yes`}>{toggleField.trueText}</label>
						</div>
						<div className='form-check form-check-inline'>
							<input disabled={readonly} {...field} {...fieldProps} checked={field.value === false} className='form-check-input' type='radio' name={`${props.fieldProps.name}`} id={`${props.fieldProps.name}-no`} value='0' onChange={_ => helpers.setValue(false)} />
							<label className='form-check-label' htmlFor={`${props.fieldProps.name}-no`}>{toggleField.falseText}</label>
						</div>
					</div>
				)
			}
			break
		default:
			input = (
				<input
					// Dynamically set the field's classes based on value, touched state, and error state
					className={`form-control ${fieldStateClasses}`}
					// Spead the Formik's stuff onto the <input/> (this value came from Formik's hook useField())
					{...field}
					// Go ahead and spread all the file field props straing into the <input/>
					{...fieldProps}
					type={type}
					placeholder={fieldProps.placeholder}
					disabled={readonly}
					style={inputStyle ? inputStyle : {}}
					value={hideInput ? field.value.replace(/./gi, '•') : field.value}
				/>
			)
			break
	}

	return (
		<div className={`form-group ${fieldStateClasses}`}>
			<label htmlFor={fieldProps.id || fieldProps.name} style={labelStyle}>
				{fieldProps.label} {fieldProps.labeltooltip ? <TooltipInfo style={{ marginBottom: 4 }} tooltipText={fieldProps.labeltooltip} /> : ''}
			</label>

			{textarea ?
				<textarea
					// Spead the Formik's stuff onto the <input/> (this came from Formik's useField() hook)
					{...field}
					// Go ahead and spread all the file field props into the <input/>
					{...fieldProps}
					className={`form-control ${fieldStateClasses}`}
					placeholder={fieldProps.placeholder}
					style={inputStyle ? inputStyle : {}}
					disabled={disabled}
				/>
				:
				<div style={{ position: 'relative' }}>
					{input}
					{icon ?
						<div className='input-icon'>{icon}</div>
						:
						null
					}
				</div>
			}

			{/* Only show errors if the field has been touched */}
			{(meta.touched || !isNullOrUndefined(field.value)) && meta.error ?
				<div className='invalid-feedback' style={{ display: 'block' }}>{meta.error}</div>
				:
				<div style={{ height: '19.4px' }} />
			}

			{/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
		</div>
	)
}


// Non-formik inputs
interface ISimpleInputProps<T> {
	id?: string
	onChange: (e: React.ChangeEvent<T>) => void
	label?: string
	placeholder?: string
	className?: string
	disabled?: boolean
	autoFocus?: boolean
}

interface ITextInputProps extends ISimpleInputProps<HTMLInputElement> {
	value?: number | string
}
export const TextInput = (props: ITextInputProps) => {
	const { id, onChange, label, placeholder, value, disabled, autoFocus } = props
	return (
		<div className="form-group">
			{label ? <label htmlFor={id}>{label}</label> : null}
			<input ref={(input) => autoFocus ? input?.focus() : null} disabled={disabled} value={value} onChange={onChange} placeholder={placeholder} type="text" className="form-control" id={id} />
		</div>
	)
}

interface ICheckboxProps extends ISimpleInputProps<HTMLInputElement> {
	id: string
	checked?: boolean
}
export const Checkbox = (props: ICheckboxProps) => {
	const { id, onChange, label, checked, className, disabled } = props
	return (
		<div className={`custom-control custom-checkbox ${className || ''} ${disabled ? 'disabled' : ''}`}>
			<input disabled={disabled} checked={checked} onChange={onChange} type="checkbox" className="custom-control-input" id={id} />
			<label className="custom-control-label" htmlFor={id}>{label}</label>
		</div>
	)
}

interface INumberInputProps extends ISimpleInputProps<HTMLInputElement> {
	value?: number | string
}
export const NumberInput = (props: INumberInputProps) => {
	const { onChange, value, disabled } = props
	return (
		<div className="form-group">
			<input disabled={disabled} value={value} onChange={onChange} type="number" className="form-control" />
		</div>
	)
}

interface IRadioProps extends ISimpleInputProps<HTMLInputElement> {
	options: ISelectFieldOption[]
	value?: number | string
}
export const Radio = (props: IRadioProps) => {
	const { onChange, label, options, value } = props
	const [name] = useState(uuidv4())
	return (
		<div>
			{label ? <label>{label}</label> : null}
			{options.map(opt => (
				<div key={opt.value} className='form-check indicate-hover'>
					<input style={{ cursor: 'pointer' }} onChange={onChange} className='form-check-input' type='radio' id={`${opt.value}`} name={name} value={`${opt.value}`} checked={opt.value == value} />
					<label style={{ cursor: 'pointer', width: '100%' }} className='form-check-label' htmlFor={`${opt.value}`}>{opt.label}</label>
				</div>
			))}
		</div>
	)
}

interface IDayPickerInputProps {
	selectedDay?: Date
	onDayChange: (date: Date) => void
	parentElement?: Element | null
	placeholderText?: string
	showTimeSelect?: boolean
	dateFilter?: (date: Date) => boolean
}
export const DayPickerInput = (props: IDayPickerInputProps) => {
	const { selectedDay, onDayChange, placeholderText, showTimeSelect, dateFilter } = props

	return (
		<DatePicker
			placeholderText={placeholderText || 'Select a date (mm/dd/yy)'}
			className='day-picker-input'
			showPopperArrow={true}
			selected={selectedDay}
			onChange={date => {
				console.log('onchange')
				// Ensure as the user types we don't accidentally send a date MSSQL can't handle
				if (date instanceof Date && moment(date).isAfter('1/1/1753') && moment(date).isBefore('12/31/9999') && selectedDay !== date) onDayChange(date)
			}}
			// onSelect={(date) => {
			// 	console.log('onselect')
			// 	onDayChange(date)
			// }}
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			showTimeSelect={showTimeSelect}
			dateFormat={showTimeSelect ? 'MMMM d, yyyy h:mm aa' : 'MM/dd/yyyy'}
			filterDate={dateFilter}
			//@ts-ignore (typescript folks haven't caught up to core library)
			filterTime={dateFilter}
		/>
	)
}

interface IDateFieldProps extends IFieldProps {
	showTimeSelect?: boolean
	dateFilter?: (date: Date) => boolean
}
export const DatePickerField = (props: IDateFieldProps) => {
	const { fieldProps, showTimeSelect, dateFilter } = props

	const [field, meta, helpers] = useField(fieldProps)
	const { error, touched } = meta

	const [selectedDay, setSelectedDay] = useState<Date>()
	useEffect(() => {
		if (field.value instanceof Date) setSelectedDay(field.value)
		if (typeof field.value === 'string' && field.value.length) setSelectedDay(new Date(field.value))
	}, [field.value])

	const fieldStateClasses = `${error && touched ? 'is-invalid' : ''}`.trim()

	return (
		<div className={`form-group ${fieldStateClasses}`}>
			<label htmlFor={fieldProps.id || fieldProps.name}>
				{fieldProps.label} {fieldProps.labeltooltip ? <TooltipInfo style={{ marginBottom: 4 }} tooltipText={fieldProps.labeltooltip} /> : ''}
			</label>

			<DayPickerInput
				showTimeSelect={showTimeSelect}
				selectedDay={selectedDay}
				placeholderText={fieldProps.placeholder}
				dateFilter={dateFilter}
				onDayChange={(date) => {
					helpers.setValue(showTimeSelect ? date.toLocaleString('en-US') : moment(date).format('MM/DD/YYYY'))
				}} />

			{/* Only show errors if the field has been touched OR if it has a value */}
			{(meta.touched || (field.value !== null && field.value !== undefined && (typeof field.value !== 'string' || field.value.length))) && meta.error ?
				<div className='invalid-feedback' style={{ display: 'block' }}>{meta.error}</div>
				:
				<div style={{ height: '19.4px' }} />
			}
		</div>
	)
}