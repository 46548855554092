
export interface IDefaultProps {
	path?: string
	children?: any
	className?: string
}

export interface IAppState {
	bootstrapped?: boolean
	authReady?: boolean

	currentUser?: firebase.default.User | null

	/* 
		We store toasts in the global state. 
		
		There is a <Toasts /> component that lives at the top level of the app. 
		That component will display any toasts that are added to global state (and handle "dismissing" toasts).
	*/
	toasts?: IToast[]

	/* 
		Similarly to toasts, alerts are stored in global state and presented by an <Alerts /> container component at the top level of the app.
		The <Alerts /> component handles displaying and dismissing alerts from the global state.
	*/
	alerts?: IAlert[]
}

export interface IToast {
	id: string
	title: string
	timestamp: Date
	body: string | JSX.Element
	autoDismissTimeOut?: number
	notDimissible?: boolean
	icon?: JSX.Element
}

export interface IAlert {
	id: string
	body: string | JSX.Element

	// size?: ModalSize
	title?: string
	footer?: JSX.Element
}

export const initialAppState: IAppState = {

}
