import React, { useEffect } from "react"
import { IAppState, initialAppState } from './stores/app-definitions'
import { IAppActions, useAppActions } from './stores/app-actions'
import { appReducer } from './stores/app-reducer'
import { Loading } from './components/loading'
import { useAuthGuard } from "./services/hooks"

export const AppActionContext = React.createContext<IAppActions | null>(null)
export const AppStateContext = React.createContext<IAppState>(initialAppState)

export const AppStoreProvider = (props: any) => {
	const [appState, actions] = useAppActions(appReducer, initialAppState)

	useAuthGuard(actions)

	useEffect(() => {
		if (appState.authReady) actions.bootstrap()

		// eslint-disable-next-line
	}, [appState.authReady])

	return appState.bootstrapped ?
		<AppActionContext.Provider value={actions}>
			<AppStateContext.Provider value={appState}>
				{props.children}
			</AppStateContext.Provider>
		</AppActionContext.Provider>
		:
		<Loading />
}