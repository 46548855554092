import { useEffect, useContext } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IDefaultProps } from '../stores/app-definitions'

export const SignOut = (props: IDefaultProps) => {
	const actions = useContext(AppActionContext)

	useEffect(() => {
	}, [actions])

	return null
}
