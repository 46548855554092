import { navigate, Router } from '@reach/router'
import React, { useContext } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IDefaultProps } from '../stores/app-definitions'

// export enum Path {

// }

// export const allPaths = Object.keys(Path).filter(key => typeof key === 'string')

export const Home = (props: IDefaultProps) => {
	const appActions = useContext(AppActionContext)!

	return (
		<div style={{ flex: 1 }} className='m-4 d-flex justify-content-center'>
			<div className='d-flex flex-column justify-content-center'>
				<h2>Verses Admin Tools</h2>
				<hr className='mb-4' />

				<button onClick={() => navigate('reset-streak')} className='btn btn-primary'>Forgive Missed Streak Days</button>

				<small onClick={appActions.logout} className='text-secondary cursor-pointer mt-4'>Sign Out</small>
			</div>
		</div>
	)
}