import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { firestore } from '../services/firebase'
import { DatePickerField } from './forms'
import { Loading, LoadingPropsSizeEnum } from './loading'
import * as Yup from 'yup'
import { DateTime } from "luxon"
import { deregisterLoadingTask, registerLoadingTask } from '../services/loading-service'


interface IStreakUserCount { id: string }

export const StreakUserCount = (props: IStreakUserCount) => {
    const { id } = props

    const [streakData, setStreakData] = useState<{ timestamp: number, duration: number }[]>()
    const [streakCount, setStreakCount] = useState<number>(0)
    const [lastMissedDay, setLastMissedDay] = useState<number>()

    const getDailyUsage = async () => {
        const dailyUsageQuery = await firestore.collection('users').doc(id).collection('dailyUsage').get()
        setStreakData(dailyUsageQuery.docs.map(o => ({ timestamp: parseInt(o.id), duration: o.data().durationOpen })))
    }

    useEffect(() => {
        getDailyUsage()
    }, [id])

    useEffect(() => {
        if (streakData) {
            let count = 0
            let previousDay = DateTime.utc().startOf('day').toSeconds()

            for (const day of streakData.reverse()) {
                const seconds = DateTime.fromSeconds(day.timestamp, { zone: 'UTC' }).startOf('day').toSeconds()
                if (day.duration >= 300 && seconds === previousDay) {
                    count++
                    previousDay = seconds - 86400
                } else {
                    setLastMissedDay(previousDay)
                    break
                }
            }

            setStreakCount(count)
        }
    }, [streakData])

    const forgiveDay = async () => {
        if (lastMissedDay) {
            const taskId = registerLoadingTask()
            await firestore.collection('users').doc(id).collection('dailyUsage').doc(lastMissedDay.toString()).set({ durationOpen: 300 })
            await getDailyUsage()
            deregisterLoadingTask(taskId)
        }
    }

    if (streakData === undefined) return <Loading size={LoadingPropsSizeEnum.small} />
    return (
        <React.Fragment>
            <span className='mr-1'><b>Current Streak:</b> {streakCount}</span>
            <span className='ml-2'><b>Last missed day:</b> {lastMissedDay ? DateTime.fromSeconds(lastMissedDay, { zone: 'UTC' }).toFormat('MM/dd/yyyy') : 'Today'}</span>
            <button onClick={forgiveDay} className='btn btn-sm btn-primary ml-2'>Forgive</button>
        </React.Fragment>
    )

}
