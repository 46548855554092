import React, { useEffect } from 'react'
import { buildTooltipProps, PopperPlacementPosition, uuidv4, } from '../services/helpers'
import { ReactComponent as XSquareIcon } from '../assets/x-square.svg'
import { ReactComponent as XSquareFillIcon } from '../assets/x-square-fill.svg'


interface ITooltipInfoProps {
	tooltipText: string
	placement?: PopperPlacementPosition
	className?: string
	style?: React.CSSProperties
}
export const TooltipInfo = (props: ITooltipInfoProps) => {
	const { tooltipText, placement, className, style } = props

	const id = uuidv4()

	useEffect(() => {
		// enableTooltip(id)

		return function cleanup() {
			// destroyTooltip(id)
		}

		//eslint-disable-next-line
	}, [])

	return (
		<svg style={style} id={id} {...buildTooltipProps({ tooltipText, placement, html: true })} className={`bi bi-info-circle cursor-pointer primary-color-hover ${className}`} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd" />
			<path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
			<circle cx="8" cy="4.5" r="1" />
		</svg>
	)
}

interface ISquareDeleteIconProps {
	onClick?: (...any: any) => void | Promise<void>
}
export const SquareDeleteIcon = (props: ISquareDeleteIconProps) => {
	const { onClick } = props

	return (
		<div onClick={onClick} className='hover-toggle'>
			<XSquareFillIcon className='text-danger show-when-hovered' />
			<XSquareIcon className='hide-when-hovered' />
		</div>
	)
}
