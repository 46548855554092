import React, { useContext, useEffect } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IDefaultProps } from '../stores/app-definitions'
import { Form, Formik } from 'formik'
import { EmailField, PasswordField } from './forms'
import * as Yup from 'yup'
import { auth } from '../services/firebase'
import { navigate } from '@reach/router'

export const SignIn = (props: IDefaultProps) => {
	const actions = useContext(AppActionContext)!

	useEffect(() => {
		if (auth.currentUser) navigate('/')
	}, [])

	return (
		<div style={{ flex: 1 }} className='d-flex justify-content-center align-items-center m-4'>
			<Formik
				initialValues={{
					email: '',
					password: ''
				}}
				validationSchema={Yup.object({
					email: Yup.string().required('Required').email('Must be a valid email address.'),
					password: Yup.string().required('Required'),
				})}
				onSubmit={actions.login}
			>
				{formikProps => (
					<Form style={{ flex: 1, maxWidth: 400 }} className='d-flex flex-column align-items-center'>
						<h1 className='display-5 mb-4'>Verses Admin</h1>

						<div style={{width: '100%'}}><EmailField fieldProps={{ name: 'email', label: 'Email' }} /></div>
						<div style={{width: '100%'}}><PasswordField fieldProps={{ name: 'password', label: 'Password' }} /></div>

						<button
							type='submit'
							disabled={!formikProps.isValid}
							className='btn btn-primary btn-lg btn-block'
							style={{ maxWidth: 300 }}
						>
							Sign In
						</button>
					</Form>
				)}
			</Formik>
		</div>
	)
}
