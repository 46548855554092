import React, { useState, useEffect, useContext } from 'react'
import { uuidv4 } from '../services/helpers'
import { IAlert } from '../stores/app-definitions'
import { AppStateContext, AppActionContext } from '../app-store-provider'

/* 
	Like <Toasts />, this is a 'container' component for displaying alerts that have been added to the global state. 
	
	It shouldn't need to be used more than once at the top level of the whole app (App.tsx).

	If you're trying to display an alert, use the addAlert action. 
	This will add an alert to the global state, and this component will handle its display and dimissal.
*/
export const Alerts = () => {
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const [alertsModalId] = useState(uuidv4())
	const [currentAlert, setCurrentAlert] = useState<IAlert>()

	/* 
		Listen to the global list of alerts and, when it changes, if there's at least one alert, displaying the first one in the list.
		This will fire when alerts are added AND removed from the list.
		If there is more than one alert, they will be shown sequentially as the user dismisses each.

		A possible modification would be showing all alerts at once if there are any, instead of one at a time.
	*/
	useEffect(() => {
		if (appState.alerts && appState.alerts.length > 0) {
			setCurrentAlert(appState.alerts[0])
		}
	}, [appState.alerts])

	/* 
		When the current alert changes, if there is an alert, show the alert modal.
	*/
	useEffect(() => {
		
	}, [currentAlert])

	const closeModal = () => {
		
		if (currentAlert) appActions.removeAlert(currentAlert)
	}

	return (
		null
		// <Modal
		// 	className='alerts-modal'
		// 	modalId={alertsModalId}
		// 	modalTitle={currentAlert?.title || 'Alert'}
		// 	footer={currentAlert?.footer || <button type='button' className='btn btn-sm btn-secondary' onClick={closeModal}>OK</button>}
		// 	closeModal={closeModal}
		// 	size={currentAlert?.size}
		// >
		// 	{currentAlert?.body}
		// </Modal>
	)
}