import $ from 'jquery'
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle'
import { useEffect, RefObject, useState } from 'react'
import { isUndefined } from 'util'
import moment from 'moment'
import { ISelectFieldOption } from '../components/forms'

// It's safe to typecast these two because we know for certain they will exist. And if they don't exist...well, hardcasting here will be the least of the issues.
export const appRoot = document.getElementById('app-root') as HTMLDivElement
export const portalRoot = document.getElementById('modal-root') as HTMLDivElement
export const toastRoot = document.getElementById('toast-root') as HTMLDivElement

export type PopperPlacementPosition = 'auto' | 'top' | 'bottom' | 'left' | 'right'

export const animateCSS = (element: HTMLElement, animationName: string, callback?: () => void) => {
	element.classList.add('animated', animationName)

	function handleAnimationEnd() {
		element.classList.remove('animated', animationName)
		element.removeEventListener('animationend', handleAnimationEnd)

		if (typeof callback === 'function') callback()
	}

	element.addEventListener('animationend', handleAnimationEnd)
}

export const okHttpStatus = (status: number) => status >= 200 && status <= 299

export const countDecimals = (num: number) => {
	if (!num) return 0
	if (Math.floor(num.valueOf()) === num.valueOf()) return 0
	return num.toString().split('.')[1].length || 0
}

export const formatCurrency = (amount: number, includeCents = false, excludeSymbol = false): string => {
	let cents = includeCents
	if (!cents) {
		cents = countDecimals(amount) > 0
	}

	if (excludeSymbol) {
		return amount.toLocaleString('en-US', {
			minimumFractionDigits: cents ? 2 : 0,
		})
	} else {
		return amount.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: cents ? 2 : 0,
		})
	}
}

export const getYearOptions = (numberOfYearsInThePastToInclude: number): ISelectFieldOption[] => {
	let options: ISelectFieldOption[] = []
	let year = moment().year()
	for (let i = 0; i < numberOfYearsInThePastToInclude; i++) {
		let yearOption = (year - i).toString()
		options.push({ value: yearOption, label: yearOption })
	}

	return options
}

export function sortListByProperty<ListType extends { [property: string]: any }>(list: ListType[], property: string, direction?: 'DESC' | 'ASC'): ListType[] {
	return list.sort((a, b) => {
		let aSortValue = a[property]
		let bSortValue = b[property]

		if (typeof aSortValue === 'string') aSortValue = aSortValue.toLowerCase()
		if (typeof bSortValue === 'string') bSortValue = bSortValue.toLowerCase()

		if (aSortValue === null && bSortValue === null) return -1
		if (bSortValue === null) { return direction === 'DESC' ? -1 : 1 }
		if (aSortValue === null) { return direction === 'DESC' ? 1 : -1 }

		if (aSortValue < bSortValue || bSortValue === null) { return direction === 'DESC' ? 1 : -1 }
		if (aSortValue > bSortValue || aSortValue === null) { return direction === 'DESC' ? -1 : 1 }
		return 0
	})
}


export const stringIsNumber = (value: string) => isNaN(Number(value)) === false

export const getTimeOptions = (start: string, end: string): ISelectFieldOption[] => {
	var startTime = moment(start, 'HH:mm')
	var endTime = moment(end, 'HH:mm')

	if (endTime.isBefore(startTime)) endTime.add(1, 'day')

	var timeOptions: ISelectFieldOption[] = []

	while (startTime <= endTime) {
		const value = moment(startTime).format('h:mm A')
		timeOptions.push({ label: value, value: value })
		startTime.add(15, 'minutes')
	}

	return timeOptions
}


// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
export const convertFileDownloadToUrl = (headers: any, data: any): string | undefined => {

	var octetStreamMime = 'application/octet-stream'
	var success = false
	let downloadUrl: string | undefined

	// Get the filename from the x-filename header or default to "download.bin"
	var filename = headers['x-filename'] || 'download.bin'
	const contentDisposition = headers['content-disposition'] as string | undefined
	if (contentDisposition && contentDisposition.includes('attachment')) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		var matches = filenameRegex.exec(contentDisposition)
		if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
	}

	// Determine the content type from the header or default to "application/octet-stream"
	var contentType = headers['content-type'] || octetStreamMime

	console.log('contentType', contentType)

	try {
		// Try using msSaveBlob if supported
		var blob = new Blob([data], { type: contentType })
		downloadUrl = URL.createObjectURL(blob)
		success = true
	} catch (ex) {
		console.log("saveBlob method failed with the following exception:")
		console.log(ex)
	}

	if (!success) {
		// Get the blob url creator
		// @ts-ignore
		var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL
		if (urlCreator) {
			// Try to simulate a click
			try {
				// Prepare a blob URL
				// eslint-disable-next-line
				var blob = new Blob([data], { type: contentType })
				var url = urlCreator.createObjectURL(blob)
				downloadUrl = url
				success = true
			} catch (ex) {
				console.log("Download link method with simulated click failed with the following exception:")
				console.log(ex)
			}

			if (!success) {
				// Fallback to window.location method
				try {
					// Prepare a blob URL
					// eslint-disable-next-line
					var blob = new Blob([data], { type: octetStreamMime })
					// eslint-disable-next-line
					var url = urlCreator.createObjectURL(blob)
					downloadUrl = url
					console.log("Download link method with window.location succeeded")
					success = true
				} catch (ex) {
					console.log("Download link method with window.location failed with the following exception:")
					console.log(ex)
				}
			}
		}
	}

	if (!success) {
		alert('Failed to display downloaded file.')
	}

	return downloadUrl
}

// https://stackoverflow.com/questions/24080018/download-file-from-an-asp-net-web-api-method-using-angularjs#24129082
export const downloadFileAttachmentFromHttpResponse = (headers: any, data: any) => {

	var octetStreamMime = 'application/octet-stream'
	var success = false

	// Get the filename from the x-filename header or default to "download.bin"
	var filename = headers['x-filename'] || 'download.bin'
	const contentDisposition = headers['content-disposition'] as string | undefined
	if (contentDisposition && contentDisposition.includes('attachment')) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		var matches = filenameRegex.exec(contentDisposition)
		if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
	}

	// Determine the content type from the header or default to "application/octet-stream"
	var contentType = headers['content-type'] || octetStreamMime

	try {
		// Try using msSaveBlob if supported
		console.log("Trying saveBlob method ...")
		var blob = new Blob([data], { type: contentType })

		if (contentType.includes('pdf')) {
			var downloadUrl = URL.createObjectURL(blob)
			window.open(downloadUrl)
		} else {
			if (navigator.msSaveBlob)
				navigator.msSaveBlob(blob, filename)
			else {
				// Try using other saveBlob implementations, if available
				// @ts-ignore
				var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob
				// eslint-disable-next-line
				if (saveBlob === undefined) throw "Not supported"
				saveBlob(blob, filename)
			}
			console.log("saveBlob succeeded")
		}


		success = true
	} catch (ex) {
		console.log("saveBlob method failed with the following exception:")
		console.log(ex)
	}

	if (!success) {
		// Get the blob url creator
		// @ts-ignore
		var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL
		if (urlCreator) {
			// Try to use a download link
			var link = document.createElement('a')
			if ('download' in link) {
				// Try to simulate a click
				try {
					// Prepare a blob URL
					console.log("Trying download link method with simulated click ...")
					// eslint-disable-next-line
					var blob = new Blob([data], { type: contentType })
					var url = urlCreator.createObjectURL(blob)
					link.setAttribute('href', url)

					// Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
					link.setAttribute("download", filename)

					// Simulate clicking the download link
					var event = document.createEvent('MouseEvents')
					event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
					link.dispatchEvent(event)
					console.log("Download link method with simulated click succeeded")
					success = true

				} catch (ex) {
					console.log("Download link method with simulated click failed with the following exception:")
					console.log(ex)
				}
			}

			if (!success) {
				// Fallback to window.location method
				try {
					// Prepare a blob URL
					// Use application/octet-stream when using window.location to force download
					console.log("Trying download link method with window.location ...")
					// eslint-disable-next-line
					var blob = new Blob([data], { type: octetStreamMime })
					// eslint-disable-next-line
					var url = urlCreator.createObjectURL(blob)
					window.location.href = url
					console.log("Download link method with window.location succeeded")
					success = true
				} catch (ex) {
					console.log("Download link method with window.location failed with the following exception:")
					console.log(ex)
				}
			}

		}
	}

	if (!success) {
		alert('Failed to display downloaded file.')
	}
}

export const download = (filename: string, text: string) => {
	var element = document.createElement('a')
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
	element.setAttribute('download', filename)

	element.style.display = 'none'
	document.body.appendChild(element)

	element.click()

	document.body.removeChild(element)
}

export enum TimeZone {
	'America/Los_Angeles' = 'America/Los_Angeles'
}

export const convertTimezone = (date: Date | string, timeZone: TimeZone) => new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString("en-US", { timeZone }))

export const normalizeTaxId = (taxId: string) => {
	/* 
		If the tax number is only 8 characters long, prepend a 0
		Admin\modules\reports\ministryAnalysis.js line 188
	*/
	if (taxId.length === 8) {
		taxId = `0${taxId}`
	}
	/* 
		If 9 characters long, format the Tax ID with a "-"
		Admin\modules\reports\ministryAnalysis.js line 192
	*/
	if (taxId.length === 9) {
		taxId = `${taxId.substr(0, 2)}-${taxId.substr(2, 9)}`
	}

	return taxId
}


interface IUseOutsideAlerter {
	refs: (RefObject<HTMLDivElement> | Element | undefined)[]
	onOutsideClickHandler: () => void
	useEffectDependencies: any[]
}
export const useOutsideClickHandler = (options: IUseOutsideAlerter) => {
	const { refs, onOutsideClickHandler, useEffectDependencies } = options
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			let outsideWasClicked = true
			refs.forEach(ref => {
				if (ref instanceof Element) {
					if (event.target instanceof Node && ref.contains(event.target)) outsideWasClicked = false

				} else if (ref) {
					if (event.target instanceof Node && ref.current && ref.current.contains(event.target)) outsideWasClicked = false
				}
			})
			if (outsideWasClicked) onOutsideClickHandler()
		}

		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		//eslint-disable-next-line
	}, [...refs, ...useEffectDependencies])
}

export interface ITooltipProps {
	'data-toggle': string
	'data-placement': string
	title: string
	'data-html'?: string
}
interface IBuildTooltipOptions {
	tooltipText: string | null
	placement?: PopperPlacementPosition
	html?: boolean
	disable?: boolean
}
export const buildTooltipProps = (options: IBuildTooltipOptions): Partial<ITooltipProps> => {
	const { tooltipText, placement, html, disable } = options

	if (tooltipText && !disable) {
		const tooltipProps: ITooltipProps = {
			'data-toggle': 'tooltip',
			'data-placement': placement || 'auto',
			title: tooltipText,
		}

		if (html) tooltipProps['data-html'] = 'true'

		return tooltipProps
	}

	return {}
}

export const uuidv4 = (): string => {
	// @ts-ignore
	//eslint-disable-next-line
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
}

export const ensureUrlIncludesHttp = (url: string): string => {
	//eslint-disable-next-line
	if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
		url = "https://" + url
	}
	return url
}

export const accountLevelIdLabel = (levelId: number): string => {
	switch (levelId) {
		case 1:
			return 'Teaching'
		case 2:
			return 'Coaching'
		case 3:
			return 'Consulting'
		default:
			return 'No Account Level'
	}
}

export const validateEin = (value: any): boolean => {
	var cleaned = ('' + value).replace(/\D/g, '')
	var match = cleaned.match(/^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/g)
	if (match) {
		return true
	}
	return false
}

export const isOdd = (x: number): boolean => (x & 1) === 1
export const isEven = (x: number): boolean => (x & 1) === 0

export const openUrlInNewTab = (url: string) => {
	Object.assign(document.createElement('a'), { target: '_blank', href: url, rel: 'noopener noreferrer' }).click()
}

export const getEnumKeyForEnumValue = (_enum: any, value: any): string => {
	const enumEntries = Object.entries(_enum)
	const entry = enumEntries.find(_entry => _entry[1] === value)

	if (entry) {
		return entry[0]
	} else {
		throw new Error('Enum does not contain this value.')
	}
}

// @ts-ignore
export const urlRegex = new RegExp(`(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})`)

export const hasDuplicates = (array: string[]) => new Set(array).size !== array.length

export const isEmail = (email: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)