import { useReducer } from "react"
import { IAppState, IToast, IAlert } from './app-definitions'
import { AppAction, AppActionType } from "./app-reducer"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { auth } from "../services/firebase"
import { uuidv4 } from "../services/helpers"

export interface IAppActions {
	bootstrap: () => Promise<void>
	authReady: () => void
	login: (args: { email: string, password: string }) => Promise<void>
	logout: () => Promise<void>
	setUser: (user: firebase.default.User | null) => void

	addToast: (toast: IToast) => void
	removeToast: (toast: IToast) => void

	addAlert: (alert: IAlert) => void
	removeAlert: (alert: IAlert) => void
}

export const useAppActions = (reducer: React.Reducer<IAppState, AppAction>, initialState: any): [IAppState, IAppActions] => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const actions = {
		bootstrap: async () => {
			if (!state.bootstrapped) {
				dispatch({ type: AppActionType.bootstrap, payload: {} })
			}
		},
		authReady: () => {
			if (!state.authReady) dispatch({ type: AppActionType.authReady })
		},
		login: async (args: { email: string, password: string }) => {
			try {
				const authQuery = await auth.signInWithEmailAndPassword(args.email, args.password)

				if (!authQuery.user) throw new Error('Authentication successful but no user was returned by Firebase.')

				dispatch({ type: AppActionType.login, payload: { user: authQuery.user } })
			} catch (e) {
				let title = 'Error'

				switch (e.code) {
					case 'auth/wrong-password':
						title = 'Wrong Password'
						break
				}

				dispatch({
					type: AppActionType.addToast, payload: {
						id: uuidv4(),
						title,
						timestamp: new Date(),
						body: e.message,
					}
				})
			}
		},
		logout: async () => {
			console.log('signing out')
			auth.signOut()
			dispatch({ type: AppActionType.logout })
		},
		setUser: (user: firebase.default.User | null) => {
			dispatch({ type: AppActionType.setUser, payload: user })
		},
		addToast: (toast: IToast) => {
			dispatch({ type: AppActionType.addToast, payload: toast })
			if (toast.autoDismissTimeOut) {
				setTimeout(() => {
					dispatch({ type: AppActionType.removeToast, payload: toast })
				}, toast.autoDismissTimeOut)
			}
		},
		removeToast: (toast: IToast) => dispatch({ type: AppActionType.removeToast, payload: toast }),

		addAlert: (alert: IAlert) => dispatch({ type: AppActionType.addAlert, payload: alert }),
		removeAlert: (alert: IAlert) => dispatch({ type: AppActionType.removeAlert, payload: alert }),

	}

	return [state, actions]
}