import { navigate } from '@reach/router'
import { Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { firestore } from '../services/firebase'
import { IDefaultProps } from '../stores/app-definitions'
import * as Yup from 'yup'
import { DatePickerField, TextField } from './forms'
import { isEmail, uuidv4 } from '../services/helpers'
import { deregisterLoadingTask, registerLoadingTask } from '../services/loading-service'
import { AppActionContext } from '../app-store-provider'
import { StreakUserCount } from './streak-user-count'

export const Streaks = (props: IDefaultProps) => {
    const appActions = useContext(AppActionContext)!

    const [users, setUsers] = useState<firebase.default.firestore.DocumentData[]>()

    const fillInStreakForUser = async (id: string) => {
        const taskId = registerLoadingTask()


        deregisterLoadingTask(taskId)
        appActions.addToast({
            id: uuidv4(),
            timestamp: new Date(),
            title: 'Successfuly Updated Streak',
            body: `Filled in streak for ${id}.`
        })
    }

    return (
        <div style={{ flex: 1 }} className='m-4 d-flex justify-content-center align-items-center'>
            <div style={{ flex: 1, maxWidth: 750 }}>
                <small onClick={() => window.history.back()} className='text-secondary cursor-pointer'>Back</small>
                <h2>Fill in Streaks</h2>
                <hr className='mb-4' />

                <Formik
                    initialValues={{
                        search: ''
                    }}
                    validationSchema={Yup.object({
                        search: Yup.string().required('Required')
                    })}
                    onSubmit={async (values) => {
                        const taskId = registerLoadingTask()

                        const searchTerm = values.search

                        if (isEmail(searchTerm)) {
                            const userQuery = await firestore.collection('users').where('email', '==', searchTerm).get()
                            setUsers(userQuery.docs.map(o => o.data()))
                        } else {
                            const userQuery = await firestore.collection('users').doc(searchTerm).get()
                            if (userQuery.exists && userQuery.data()) {
                                setUsers([userQuery.data()!])
                            } else {
                                setUsers(undefined)
                            }
                        }

                        deregisterLoadingTask(taskId)
                    }}
                >
                    {formikProps => (
                        <Form>
                            <TextField fieldProps={{ name: 'search', label: 'UID or Email (case sensitive)' }} />
                            <button disabled={!formikProps.isValid} type='submit' className='btn btn-primary mb-4'>Find User</button>
                        </Form>
                    )}
                </Formik>

                {users && users.length > 0 ?
                    <div>
                        {users.map(o => (
                            <div key={o.id} className='d-flex align-items-center'>
                                <span className='mr-2'>{o.email}</span>
                                <StreakUserCount id={o.id} />
                            </div>
                        ))}
                    </div>
                    :
                    <div>No users found.</div>
                }
            </div>
        </div>
    )
}