import { IAppState, IToast, IAlert } from './app-definitions'
import { navigate } from '@reach/router'

export enum AppActionType {
	loading = 'loading',
	doneLoading = 'doneLoading',
	bootstrap = 'bootstrap',
	authReady = 'authReady',
	navigate = 'navigate',
	logout = 'logout',
	login = 'login',
	setUser = 'setUser',
	addToast = 'addToast',
	removeToast = 'removeToast',
	addAlert = 'addAlert',
	removeAlert = 'removeAlert',

}

interface IPayloadLessAction {
	type:
	AppActionType.loading |
	AppActionType.doneLoading |
	AppActionType.logout |
	AppActionType.authReady
}

interface IBootstrapAction {
	type: AppActionType.bootstrap
	payload: {
		
	}
}

interface ILoginAction {
	type: AppActionType.login
	payload: {
		user: firebase.default.User
	}
}

interface ISetUserAction {
	type: AppActionType.setUser
	payload: firebase.default.User | null
}

interface IAddToastAction {
	type: AppActionType.addToast
	payload: IToast
}

interface IRemoveToastAction {
	type: AppActionType.removeToast
	payload: IToast
}

interface IAddAlertAction {
	type: AppActionType.addAlert
	payload: IAlert
}

interface IRemoveAlertAction {
	type: AppActionType.removeAlert
	payload: IAlert
}


interface INavigateAction {
	type: AppActionType.navigate
	payload: {
		url: string
	}
}


export type AppAction =
	IPayloadLessAction |
	IBootstrapAction |
	ISetUserAction |
	ILoginAction |
	INavigateAction |

	IAddToastAction |
	IRemoveToastAction |
	IAddAlertAction |
	IRemoveAlertAction

export function appReducer(state: IAppState, action: AppAction): IAppState {
	let newState = state

	switch (action.type) {
		case AppActionType.bootstrap:
			newState = {
				...newState,
				bootstrapped: true,
			}
			break
			case AppActionType.authReady:
				newState ={...newState, authReady: true}
				break
		case AppActionType.navigate:
			const url = action.payload && action.payload.url
			navigate(url)
			break
		case AppActionType.login:
			newState = {
				...newState,
			}

			navigate('/')
			break
		case AppActionType.logout:
			newState = { ...newState, currentUser: null }
			break
		case AppActionType.setUser:
			newState = { ...newState, currentUser: action.payload }
			break
		case AppActionType.addToast:
			newState = { ...newState, toasts: [...newState.toasts || [], action.payload] }
			break
		case AppActionType.removeToast:
			newState = { ...newState, toasts: newState.toasts ? [...newState.toasts].filter(toast => toast.id !== action.payload.id) : [] }
			break
		case AppActionType.addAlert:
			newState = { ...newState, alerts: [...newState.alerts || [], action.payload] }
			break
		case AppActionType.removeAlert:
			newState = { ...newState, alerts: newState.alerts ? [...newState.alerts].filter(alert => alert.id !== action.payload.id) : [] }
			break

		default:
			break
	}

	return newState
}
