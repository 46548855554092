import { uuidv4 } from './helpers'

const loadingTasks: Set<string> = new Set();
const loadingListeners: ((loading: boolean) => void)[] = [];

export const registerLoadingListener = (
    listener: (loading: boolean) => void
) => {
    loadingListeners.push(listener);
};

export const registerLoadingTask = (): string => {
    const id = uuidv4();
    loadingTasks.add(id);
    refreshLoadingState();
    return id;
};

export const deregisterLoadingTask = (id: string) => {
    loadingTasks.delete(id);
    refreshLoadingState();
};

export const clearAllLoaders = () => {
    loadingTasks.clear()
    refreshLoadingState()
}

const refreshLoadingState = () => {
    // Broadcast loading state
    loadingListeners.forEach(listener => {
        listener(loadingTasks.size > 0);
    });
};