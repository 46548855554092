import React from 'react'
import { AppStoreProvider } from './app-store-provider'
import { Router } from '@reach/router'
import { SignIn } from './components/sign-in'
import { SignOut } from './components/sign-out'
import { Home } from './components/home'
import { AppLoadingOverlay } from './components/loading'
import { Toasts } from './components/toasts'
import { Alerts } from './components/alerts'
import { Streaks } from './components/streaks'

const App = () => {

	return (
		<AppStoreProvider>
			<Router>
				<SignIn path='signin' />
				<SignIn path='login' />
				<SignOut path='signout' />
				<SignOut path='logout' />
				<Home path='/*' />
				<Streaks path='reset-streak' />
			</Router>
			<AppLoadingOverlay />
			<Toasts />
			<Alerts />
		</AppStoreProvider>
	)
}

export default App